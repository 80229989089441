import {useAuthStore} from "@/stores";

export default {

    methods:{
      async login(access,password){
          let loader = this.$loading.show({loader: 'bars'});
          let responseModel = await this.authStore.login(access,password)
          if(responseModel.status=="Success") {
              await this.authStore.setupUser()
          }
          loader.hide()
          return responseModel
      },
     async setupUser(){
          return await this.authStore.setupUser()
     },
     getUser(){
          this.authStore.user
     }
    },
    data(){
        return {
            authStore : useAuthStore(),
        }
    }
}
