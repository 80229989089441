<template>
 <router-view/>
</template>

<script>
import swal from 'sweetalert2'
window.Swal = swal
export default {
  name: 'App',
}
</script>

<style>
body{
  height: 100vh;
}
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 16px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.swal2-confirm {
  font-size: 14px !important;
  font-weight: 800 !important;
}

.swal2-deny {
  font-size: 14px !important;
  font-weight: 800 !important;
}

.swal2-cancel {
  font-size: 14px !important;
  font-weight: 800 !important;
}

.swal2-html-container {
  font-size: 18px !important;
  font-weight: 800 !important;
}

.b-table >thead{
  border-top: 1px solid var(--kt-input-border-color) !important;
  text-align: center;
  background-color: black;
  color: white;
}
.b-table >tbody{
  border: 1px solid var(--kt-input-border-color) !important;
  text-align: center;
}
.b-table >tbody > tr > td{
  vertical-align: middle;
  padding: 5px 5px 5px 5px;
}

.tox-notification { display: none !important }

.page-item.active {
  font-width: 800;
}

.form-select{
  font-weight: 800;
}
.form-select > option{
  font-weight: 800;
}

</style>