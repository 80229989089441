import {JwtAxios} from "@/packaging";

export const settingAPI = {
    async GetCoupangAPI(){
        const responseModel = await JwtAxios.get(`/Setting/GetCoupangAPI`);
        return responseModel
    },

    async SetCoupangAPI(model){
        const responseModel = await JwtAxios.post(`/Setting/SetCoupangAPI`,model);
        return responseModel
    },

    async SetCoupangConfigure(model){
        const responseModel = await JwtAxios.post(`/Setting/SetCoupangConfigure`,model);
        return responseModel
    },

    async SetNaverKeywordAPI(model){
        const responseModel = await JwtAxios.postget(`/Setting/SetNaverKeywordAPI`,model);
        return responseModel
    },
    async SetProhibitionWord(model){
        const responseModel = await JwtAxios.postget(`/Setting/SetProhibitionWord`,`"${model}"`);
        return responseModel
    },

    async GetProhibitionWord(model){
        const responseModel = await JwtAxios.post(`/Setting/GetProhibitionWord`,model);
        return responseModel
    },

    async GetNaverKeywordAPI(){
        const responseModel = await JwtAxios.get('/Setting/GetNaverKeywordAPI');
        return responseModel
    },

    async GetCoupangShipment(){
        const responseModel = await JwtAxios.get('/Setting/GetCoupangShipment');
        return responseModel
    },

    async GetCoupangConfigure(){
        const responseModel = await JwtAxios.get('/Setting/GetCoupangConfigure');
        return responseModel
    },

    async GetCoupangReturn(){
        const responseModel = await JwtAxios.get('/Setting/GetCoupangReturn');
        return responseModel
    },
    async GetCoupangDeliveryCompany(){
        const responseModel = await JwtAxios.get('/Setting/GetCoupangDeliveryCompany');
        return responseModel
    },
    async fileUpload(data,type){
        const responseModel = await JwtAxios.imageUpload(data,type)
        return responseModel
    },
    async GetUserTopBottomImage(type){
        const responseModel = await JwtAxios.get(`/Image/GetUserTopBottomImage?type=${type}`);
        return responseModel
    },

    async deleteProhibitionWord(keyword){
        const responseModel = await JwtAxios.deleteget(`/Setting/DeleteProhibitionWord`,`"${keyword}"`);
        return responseModel
    },
    async DownloadProhibitXls(){
        const responseModel = await JwtAxios.get_excel(`/Setting/DownloadProhibitXls`);
        return responseModel
    },


}