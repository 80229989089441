import {JwtAxios} from "@/packaging";

export const dashboardAPI = {
    async GetDashboardInfo(){
        const responseModel = await JwtAxios.get(`/Dashboard/GetDashboardInfo`);
        return responseModel
    },
    async GetWorkLog(){
        const responseModel = await JwtAxios.get(`/Dashboard/GetWorkLog`);
        return responseModel
    },

}