import useAuthStore from "@/mixins/globals/stores/useAuthStore";
import swalAlert from "@/mixins/globals/module/swalAlert";
import strHelper from "@/mixins/globals/module/strHelper";

export default {
    install(Vue) {
        Vue.mixin(useAuthStore)
        Vue.mixin(swalAlert)
        Vue.mixin(strHelper)
    }
}