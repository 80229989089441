export default {

    methods:{
        sleep(ms) {
            const wakeUpTime = Date.now() + ms;
            while (Date.now() < wakeUpTime) {}
        },
        showCustomAlert(title) {
            Swal.fire({
                title: '',
                html: '<div class="mb-5"><img src="/assets/main/simle__sidebar__icon.png" width="96"/></div><div>'+title+'</div>',
                confirmButtonColor: '#009ef7',
                confirmButtonText: '확인'
            })
        }
    },
}
