import { createWebHistory, createRouter } from "vue-router";
import { useAuthStore } from '@/stores';
import layout from "@/layout";

const routes = [
    {
        path: "/app",
        component: layout,
        children: [
            {
                path: "index",
                name: "Main",
                component: () => import("./views/app/index"),
                meta: { title: '메인화면',subTitle:'' }
            },
            {
                path: "plan",
                name: "Plan",
                component: () => import("./views/app/plan"),
                meta: { title: '플랜',subTitle:'' }
            },
            {
                path: "collection",
                name: "Collection",
                component: () => import("./views/app/collection"),
                meta: { title: '상품 등록',subTitle:'' }
            },
            {
                path: "uploadList",
                name: "UploadList",
                component: () => import("./views/app/uploadList"),
                meta: { title: '등록 리스트',subTitle:'' }
            },
            {
                path: "setting",
                name: "Setting",
                component: () => import("./views/app/setting"),
                meta: { title: '설정',subTitle:'' }
            },
            {
                path: "mypage",
                name: "MyPage",
                component: () => import("./views/app/myPage"),
                meta: { title: '마이페이지',subTitle:'' }
            },
            {
                path: "plan",
                name: "Plan",
                component: () => import("./views/app/plan"),
                meta: { title: '플랜',subTitle:'' }
            },
            {
                path: "admin",
                name: "Admin",
                component: () => import("./views/app/admin"),
                meta: { title: '관리자',subTitle:'' }
            }
        ]
    },
    {
        path: "/testEx",
        name: "testEx",
        component: () => import('./views/testEx')
    },
    {
        path: "/",
        name: "Home",
        component: () => import('./views/user/login')
    },
    {
        path: "/user/tempPassword",
        name: "TempPassword",
        component: () => import('./views/user/Modal/SendTempPassWordMailModal')
    },
    {
        path: "/user/login",
        name: "Login",
        component: () => import('./views/user/login')
    },
    {
        path: "/user/authPhone",
        name: "authPhone",
        component: () => import('./views/user/authPhone')
    },
    {
        path: "/user/Modal/CheckplusError",
        name: "CheckplusError",
        component: () => import('./views/user/Modal/CheckplusError')
    },
    {
        path: "/user/Modal/CheckplusSuccess",
        name: "CheckplusSuccess",
        component: () => import('./views/user/Modal/CheckplusSuccess')
    },
    {
        path: "/user/register",
        name: "register",
        component: () => import('./views/user/register')
    },
    {
        path: "/user/termsOfservice",
        name: "termsOfservice",
        component: () => import('./views/user/termsOfservice')
    },
    {
        path: "/user/privacyPolicy",
        name: "privacyPolicy",
        component: () => import('./views/user/privacyPolicy')
    }
];


export const router = createRouter({
    history: createWebHistory(),
    routes,
});



router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/testEx','/user/tempPassword','/api','/user/login','/','/app/landing','/user/authPhone','/user/Modal/CheckplusError','/user/Modal/CheckplusSuccess','/user/register','/user/privacyPolicy','/user/termsOfservice'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.accessToken) {
        auth.returnUrl = to.fullPath;
        return '/user/login';
    }
});