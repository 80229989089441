import axios from "axios";
import { useAuthStore } from '@/stores';
import VueCookies from 'vue-cookies'

export const JwtAxios = {
    get: request('GET'),
    post: request('POST'),
    postget: request('POST_GET'),
    delete: request('DELETE'),
    patch: request('PATCH'),
    deleteget: request('DELETE_GET'),
    get_excel: request('GET_EXCEL'),
    post_excel: request('POST_EXCEL'),
    imageUpload : imageUpload
};
async function imageUpload(data, type) {
    var fd = new FormData()
    fd.append('file', data)

    let header = authHeader()
    header['Content-Type'] = 'multipart/form-data';
    header['accept'] = 'text/plain'
    var response = {}
    try {
        response = await axios.post(`${process.env.VUE_APP_API_URI}/Image/UploadImage?type=${type}`, fd, {
            headers: header
        })
    } catch (e) {
        return false
    }

    return response.data
}

function request(method) {
    return (path, body) => {
        let url = process.env.VUE_APP_API_URI + path

        const requestOptions = {
            method,
            headers: authHeader()
        };
        if(method == 'GET' && body){
            url += "?" + jsonToQuery(body)
        }else if ((method=='POST' || method=='PUT' || method=='DELETE' || method=='PATCH') && body) {
            requestOptions.headers['Content-Type'] = 'application/json charset=utf-8';
            requestOptions.body = JSON.stringify(body);
        }
        else if ((method=='GET_EXCEL')) {
            requestOptions.headers['accept'] = 'application/json';
            return axios({
                method: "GET",
                url,
                headers: requestOptions.headers,
                responseType: 'blob'
            }).then(handleResponse).catch(handleError);
        }
        else if ((method=='POST_EXCEL') && body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.headers['accept'] = 'application/json';
            requestOptions.body = body;
            return axios({
                method: "POST",
                url,
                data:body,
                headers: requestOptions.headers,
                responseType: 'blob'
            }).then(handleResponse).catch(handleError);
        }
        else if ((method=='POST_GET') && body) {
            method="POST"
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.headers['accept'] = 'application/json';
            requestOptions.body = body;
        }
        else if ((method=='DELETE_GET') && body) {
            method="DELETE"
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.headers['accept'] = 'application/json';
            requestOptions.body = body;
        }

        return axios({
            method,
            url,
            data:body,
            headers: requestOptions.headers,
        }).then(handleResponse).catch(handleError);
    }
}


function authHeader() {
    //return authheader with jwt if user is logged in and request is to the api url
    const { accessToken } = useAuthStore();

    if (accessToken != null) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        return {};
    }
}


function handleResponse(response) {
    const data = response.data

    if (response.status != 200) {
        const { refreshToken, logout } = useAuthStore();
        if ([401, 403].includes(response.status) && refreshToken) {
            logout();
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    }

    return data;
}

function handleError(error){
    let response = error.response
    if( response == undefined) { return false }

    let data = response.data
    if ([401, 403].includes(response.status)) {
        const { refreshToken, logout } = useAuthStore();
        if (refreshToken) {
            logout();
        }
    }

    return data
}

function jsonToQuery(param) {
    if (typeof param != "object") {
        return false
    }

    var str = ""
    for (var key in param) {
        str += `${key}=${param[key]}&`
    }

    return str
}