import { defineStore } from 'pinia';
import { router } from "../../router";
import { userAPI } from "../../api";

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        accessToken: localStorage.getItem('accessToken'),
        refreshToken:  localStorage.getItem('refreshToken'),
        user: null,
    }),
    actions: {
        async setupUser(){
            this.user = await userAPI.GetUser()
            if(this.user.username == undefined || this.user.username == null){
                this.user = null
            }
            return this.user
        },

        async login(account, password) {
            const responseModel = await userAPI.Login(account, password)
            if(responseModel.status!="Success"){
                return responseModel;
            }

            this.accessToken = responseModel.data.accessToken;
            this.refreshToken = responseModel.data.refreshToken;
            localStorage.setItem('accessToken', this.accessToken);
            localStorage.setItem('refreshToken', this.refreshToken);

            return responseModel;
        },


        logout() {
            this.accessToken = null;
            this.refreshToken = null;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            router.push('/user/login');
        },
    }
})