import {JwtAxios} from "@/packaging";

export const userAPI = {
    async Login(account, password){
        const responseModel = await JwtAxios.post(`/Auth/login`,{
            "account": account,
            "password": password,
            "version": "9.9.9.9"
        });
        return responseModel
    },
    async ChangePassWord(model){
        const responseModel = await JwtAxios.post(`/Account/ChangePassWord`,model);
        return responseModel
    },
    async SendTempPassWordMail(model){
        const responseModel = await JwtAxios.post(`/Account/SendTempPassWordMail`,model);
        return responseModel
    },
    async GetUser(){
        const responseModel = await JwtAxios.get('/Auth/user');
        return responseModel
    },
    async GetPhoneAuthData(){
        const responseModel = await JwtAxios.get('/PG/GetPhoneAuthData');
        return responseModel
    },
    async ConfromPhoneAuthData(model){
        const responseModel = await JwtAxios.post('/PG/ConfromPhoneAuthData',model);
        return responseModel
    }
}